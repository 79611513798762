import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import SearchField from './TrackTables/ActionComponents/SearchField';
import CollectionsSlider from './TrackTables/ActionComponents/CollectionsSlider';
import TrackTable from './TrackTables/TrackTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const Search = (props) => {

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

        if (props.user && !props.user.isAuthenticated) {
            navigate('/login');
        }

    }, [navigate, props.user]);

    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                <Grid item xs={12}>
                    <SearchField />
                </Grid>
                <Grid item xs={12}>
                    <h2 style={{ float: 'left' }}>
                        Curated Collections
                    </h2>
                </Grid>
                <Grid className="collections-container" item xs={12}>
                    <CollectionsSlider />
                </Grid>

                <Grid item xs={12}>
                    <TrackTable headerTitle="Latest Tracks" apiUrl="tvproscategory?" />
                </Grid>
            </Grid>

        </Container>
    );

}
function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps)(Search);
