import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import apiconfig from '../../config/api-config.js';
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { updateAuthStatus, setAuthUser, updateAuthenticating, smsNotify, requestAffiliateAccess } from '../FlikTraxStore/Actions/userActions';
import { apiIsLoading } from '../FlikTraxStore/Actions/apiActions';
import LoadingBackdrop from '../Utilities/LoadingBackdrop';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField, FormHelperText } from '@mui/material';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCompactDisc);

const style = {
    position: 'relative',
    top: '53%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '55%',
    bgcolor: '#272727',
    border: '1px solid #fff',
    boxShadow: 1,
    p: 3,
}


const RegisterForm = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const affiliateError = useSelector((state) => state.user && state.user.affiliateError);


    useEffect(() => {
        if (props.user && props.user.isAuthenticated === true) {
            navigate('/');
        }
    }, [props.user, navigate]);

    const [form, setForm] = React.useState('register');

    const [loading, setLoading] = React.useState(false);


    const [codeSent, setCodeSent] = React.useState(false);

    const [userAttributes, setUserAttributes] = React.useState({
        username: '',
        password: '',
        attributes: {
            'custom:firstname': '',
            'custom:lastname': '',
            'custom:company': '',
        }
    });


    const renderRegisterForm = () => {

        return (
            <Container className="ft-container" maxWidth="md">
                <LoadingBackdrop open={loading} />
                <Box sx={style} >
                    <img style={{ maxHeight: 100, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                    <h1>Request Access</h1>
                    <Formik
                        initialValues={{
                            firstname: "",
                            lastname: "",
                            company: "",
                            useremail: "",
                        }}
                        validationSchema={
                            Yup.object().shape({
                                firstname: Yup.string()
                                    .min(2, 'Must include at least 8 characters.')
                                    .max(50, 'Limit is 50 characters')
                                    .required('First Name is required.'),
                                lastname: Yup.string()
                                    .min(2, 'Must include at least 8 characters.')
                                    .max(50, 'Limit is 50 characters')
                                    .required('Last Name is required.'),
                                company: Yup.string()
                                    .optional(),
                                useremail: Yup.string()
                                    .email('Must be a valid email.')
                                    .max(255)
                                    .required('Email is required.'),
                            })}

                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {

                            setUserAttributes(value);

                            try {
                                setLoading(true);
                                value.affiliate = "janson";
                                axios({
                                    baseURL: 'https://api.fliktrax.com/services',
                                    method: 'post',
                                    auth: {
                                        username: apiconfig.api.APIUSERNAME,
                                        password: apiconfig.api.APIPASSWORD
                                    },
                                    data: {
                                        service: 'add-affiliate-user',
                                        payload: value
                                    },
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                })
                                    .then(response => {
                                        console.log(response.data);
                                        switch (response.data.error) {
                                            case 'duplicate':
                                                setStatus({ success: false })
                                                setErrors({ submit: "This email address is already registered with Janson Music Resource." });
                                                setSubmitting(false)
                                                setLoading(false);
                                                break;

                                            default:
                                                setSubmitting(false);
                                                setStatus({ success: true });
                                                setLoading(false);
                                                setForm('confirm');
                                        }
                                    })


                            } catch (err) {
                                console.log(err);
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (

                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.firstname && errors.firstname)}
                                            fullWidth
                                            helperText={touched.firstname && errors.firstname}
                                            label="First Name"
                                            margin="normal"
                                            name="firstname"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={Boolean(touched.lastname && errors.lastname)}
                                            fullWidth
                                            helperText={touched.lastname && errors.lastname}
                                            label="Last Name"
                                            margin="normal"
                                            name="lastname"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastname}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.company && errors.company)}
                                            fullWidth
                                            helperText={touched.company && errors.company}
                                            label="Company (optional)"
                                            margin="normal"
                                            name="company"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email"
                                            margin="normal"
                                            name="useremail"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box sx={{
                                                mt: 3
                                            }}>
                                                <FormHelperText error>
                                                    <h2>{errors.submit}</h2>
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="primary"
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            bgcolor="primary"
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            Request Access {loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>

                    <Divider />
                    <p style={{ marginTop: 25 }}>
                        Already have an account?
                        <Link style={{ textDecoration: 'underline', marginLeft: 10 }} to="/login" >
                            Login here.
                        </Link>
                    </p>
                </Box>
            </Container>
        );
    }

    const renderConfirmForm = () => {

        return (
            <Container className="ft-verify" maxWidth="md">

                <Box sx={style} style={{ minHeight: '55vh' }}>
                    <img style={{ maxHeight: 100, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                    <h1>Request Access</h1>
                    <p>
                        Thank you for requesting access to Janson Media Music!
                    </p>
                    <p>
                        Please allow up to 1 business day to verify your request.
                    </p>
                    <p>
                        Please continue to check your email at {userAttributes.useremail} for confirmation login information.
                    </p>
                    <p style={{ marginTop: 50 }}>
                        <Link style={{ textDecoration: 'underline', marginLeft: 10 }} to="/login" >
                            <Button
                                variant="primary"
                                fullWidth
                                size="large"
                                color="primary"
                                bgcolor="primary"
                                type="submit"
                            >
                                Login
                            </Button>
                        </Link>
                    </p>


                </Box>
            </Container>
        );
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

    }, []);

    switch (form) {
        case 'confirm':
            return renderConfirmForm();

        default:
            return renderRegisterForm();
    }

}

RegisterForm.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
    smsNotify: PropTypes.func.isRequired,
    apiIsLoading: PropTypes.func.isRequired,
    requestAffiliateAccess: PropTypes.func.isRequired,
}


function mapStateToProps(state, props) {
    return {
        user: state.user,
        api: state.api,
        func: props.func
    };
}

export default connect(mapStateToProps, {
    updateAuthStatus,
    setAuthUser,
    updateAuthenticating,
    apiIsLoading, smsNotify,
    requestAffiliateAccess
})(RegisterForm);