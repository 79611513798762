import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateFeaturedPlaying, updateAutoPlay, updateNowPlaying, updateIsPlaying, updateIsPaused, setPlayerStatus, updatePlayerPosition } from '../../../FlikTraxStore/Actions/audioPlayerActions';
import { gaPushAudioPreview } from '../../../FlikTraxStore/Actions/googleAnalyticsActions';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faPlayCircle, faPauseCircle);


const PlayPause = (props) => {

  const featuredPlaying = useSelector((state) => state.audioplayer && state.audioplayer.featuredPlaying);


  const playerIsPaused = useSelector((state) => state.audioplayer && state.audioplayer.isPaused);

  const defaultPlaylist = useSelector((state) => state.api && state.api.embeddedData && state.api.embeddedData[0]);

  const updatePlaying = (event) => {

    if (featuredPlaying) {
      props.updateFeaturedPlaying(false);
    }

    props.updateAutoPlay(defaultPlaylist);

    let ds = event.currentTarget.dataset;

    let targetId = ds.trackid;
    let nowPlaying = ds;
    let currenttid = nowPlaying.trackid;

    props.updateNowPlaying(nowPlaying);

    if ((props.audioplayer.currentTrackId !== currenttid) && (!props.audioplayer.isPlaying)) {
      props.gaPushAudioPreview(JSON.parse(JSON.stringify(nowPlaying)));
    }

    if (props.audioplayer.currentTrackId === targetId) {
      props.updateIsPlaying(!props.audioplayer.isPlaying);
      props.updatePlayerPosition(props.audioplayer.position);
    } else {
      props.updateIsPlaying(true);
      props.updatePlayerPosition(0);
    }
  }

  return (

    ((props.audioplayer) && (props.audioplayer.currentTrackId === props.trackID) && (props.audioplayer.isPlaying)) ? (
      <IconButton id={props.trackID}  {...props.dataAttr} onClick={(event) => { updatePlaying(event) }} size="small" aria-label="Track Info" color="inherit">
        <FontAwesomeIcon color="secondary" icon={faPauseCircle} size="2x" />
      </IconButton>
    ) :

      <IconButton id={props.trackID} {...props.dataAttr} onClick={(event) => { updatePlaying(event) }} size="small" aria-label="Track Info" color="inherit">
        <span id={(playerIsPaused) && (props.audioplayer.currentTrackId === props.trackID) ? "pauseBorder" : ""}>
          <FontAwesomeIcon color="secondary" icon={faPlayCircle} size="2x" />
        </span>
      </IconButton>

  );
}

const mapStateToProps = state => ({
  api: state.api,
  audioplayer: state.audioplayer,
  user: state.user,
})

PlayPause.propTypes = {
  updateFeaturedPlaying: PropTypes.func.isRequired,
  switchPlayerMode: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  updateNowPlaying: PropTypes.func.isRequired,
  updateIsPlaying: PropTypes.func.isRequired,
  updateIsPaused: PropTypes.func.isRequired,
  updateAutoPlay: PropTypes.func.isRequired,
  gaPushAudioPreview: PropTypes.func.isRequired,
}


export default connect(mapStateToProps,
  {
    updateNowPlaying,
    updateAutoPlay,
    updateFeaturedPlaying,
    updateIsPlaying,
    updateIsPaused,
    setPlayerStatus,
    updatePlayerPosition,
    gaPushAudioPreview,
  })(PlayPause);