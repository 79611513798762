import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Home = (props) => {


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

    }, []);


    function AboutButton(route, text) {
        return (
            <div>
                <Link to={route}>
                    <Button
                        variant="primary"
                        fullWidth
                        size="large"
                        color="primary"
                        bgcolor="primary"
                    >
                        {text}
                    </Button>
                </Link>
            </div >
        )
    }

    return (
        <Container className="ft-container" maxWidth="md">
            <h1>Support</h1>
            <Divider style={{ marginBottom: 25 }} />
            {/* <h3>Your License To "HitItHard"</h3> */}
            <Box>
                <Parallax
                    bgImage="/images/home/VoiceOverBG.jpg"
                    bgImageStyle={{ opacity: '.2' }}
                    strength={500}
                >
                    <Box sx={{ flexGrow: 1, p: 4 }}  >
                        <Typography variant="h6" pb={2} style={{ color: 'white', fontWeight: 600 }}>
                            To get full access to FlikTrax TV Pros, create an account by <Link style={{color:"#fe6204"}} to="/register">Registering</Link>. Upon registration you'll receive a confirmation code, which is needed to finalize your account. Once your account is confirmed, you can <Link style={{color:"#fe6204"}} to="/login">Log In</Link> and enjoy all the Download/Save/Share features offered.
                        </Typography>

                        <Typography variant="h6" pb={2} style={{ color: 'white', fontWeight: 600 }}>
                            If you have any technical issues upon Log In or during usage, please contact us at <a style={{color:"#fe6204"}} href="mailto:support@fliktrax.com">support@fliktrax.com</a> and our technical staff will quickly remedy the issue.
                        </Typography>

                        <Typography variant="h6" pb={2} style={{ color: 'white', fontWeight: 600 }}>
                            For Custom Music and/or Curated Search requests, simply fill out the <Link style={{color:"#fe6204"}} to="/request">Request Form</Link>
                        </Typography>

                        <Typography variant="h6" pb={2} style={{ color: 'white', fontWeight: 600 }}>
                            While every track's Writers/Publishing information is available by clicking the "i" button next to any track, FlikTrax will happily fill out our portion of any Cue Sheets at the end of Production. We just ask that you keep track of Filenames and/or Track Names used and our music pros will then return all the necessary information promptly. Please send all Cue Sheet requests to <a style={{color:"#fe6204"}} href="mailto:info@fliktrax.com">info@fliktrax.com</a>.
                        </Typography>
                    </Box>
                </Parallax>

            </Box>
        </Container >
    )
}

export default Home;