import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import apiconfig from '../../../../config/api-config.js';
import { fetchApiData, switchMasterMode, switchApiPage, switchApiUrl } from '../../../FlikTraxStore/Actions/apiActions';
import Slider from 'react-slick';
import CategoryCard from './CategoryCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CollectionsSlider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      slidercategories: {}
    };
  }

  componentDidMount() {
    let axiosUrl = 'https://api.fliktrax.com/newreleases?playlist=[]';
    axios({
      baseURL: axiosUrl,
      method: 'get',
      auth: {
        username: apiconfig.api.APIUSERNAME,
        password: apiconfig.api.APIPASSWORD
      },
      headers: {
        "Content-Type": "application/hal+json"
      },
      //timeout: 2000,
    })
      .then(response => {
        this.setState({ slidercategories: response.data._embedded.newreleases });
      })
  }

  render() {


    var settings = {
      dots: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      lazyLoad: true,
      initialSlide: 0,
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };

    if (this.state.slidercategories.length !== undefined) {

      return (
        <Slider {...settings} >
          {this.state.slidercategories.length !== undefined &&
            this.state.slidercategories.map((data) => {
              return (
                <>
                    <CategoryCard CardData={data} />
                </>
              );
            })}
        </Slider>
      );
    } else {
      return (<div></div>);
    }
  }
}

CollectionsSlider.propTypes = {
  fetchApiData: PropTypes.func.isRequired,
  switchMasterMode: PropTypes.func.isRequired,
  switchApiUrl: PropTypes.func.isRequired,
  switchApiPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  api: state.api,
})

export default connect(mapStateToProps,
  {
    fetchApiData,
    switchMasterMode,
    switchApiUrl,
    switchApiPage,
  })(CollectionsSlider);