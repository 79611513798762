import * as React from 'react';
import { Auth } from 'aws-amplify';
import { connect, useDispatch } from 'react-redux';
import { setAuthUser } from '../../../FlikTraxStore/Actions/userActions';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Formik } from "formik";
import * as Yup from "yup";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField, FormHelperText } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc, faUser } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCompactDisc, faUser);



const MyAccount = (props) => {

    const currentUserAttributes = props.user && props.user.isAuthenticated && props.user.user ? props.user.user.attributes : null;

    const [open, setOpen] = React.useState(false);

    const [showPasswords, handleShowPasswords] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const Notify = (notification, variant = "success") => {
        enqueueSnackbar(notification, { variant: variant});
    }

    const navigate = useNavigate();

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const renderPlaylistForm = () => {
        return (
            <Box>
                <img style={{ maxHeight: 55, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                <h1>Edit Profile</h1>
                <Formik
                    initialValues={{
                        username: "",
                        firstName: currentUserAttributes !== null ? currentUserAttributes['custom:firstname'] : "",
                        lastName: currentUserAttributes !== null ? currentUserAttributes['custom:lastname'] : "",
                        company: currentUserAttributes !== null ? currentUserAttributes['custom:company'] : "",
                        email: currentUserAttributes !== null ? currentUserAttributes['email'] : "",
                        password: "",
                        confirmPassword: "",

                    }}
                    validationSchema={
                        Yup.object().shape({
                            firstName: Yup.string()
                                .min(2, 'Must include at least 8 characters.')
                                .max(50, 'Limit is 50 characters')
                                .required('First Name is required.'),
                            lastName: Yup.string()
                                .min(2, 'Must include at least 8 characters.')
                                .max(50, 'Limit is 50 characters')
                                .required('Last Name is required.'),
                            company: Yup.string()
                                .optional(),
                            email: Yup.string()
                                .email('Must be a valid email.')
                                .max(255)
                                .required('Email is required.'),
                            password: Yup.string()
                                .min(8, 'Must include at least 8 characters.')
                                .max(25, 'Passwords may not exceed 25 characters.')
                                .required('Old Password is required.'),
                            passwordConfirmation: Yup.string()
                                .min(8, 'Must include at least 8 characters.')
                                .max(25, 'Passwords may not exceed 25 characters.')
                                .required('New Password is required.'),
                        })}

                    onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                        let user = await Auth.currentAuthenticatedUser();
                        try {

                            setLoading(true);
                            setSubmitting(true)

                            let authUsername = value.email;
                            let authPassword = value.password;

                            let authAttributes = {
                                'username': authUsername,
                                'password': authPassword,
                                attributes: {
                                    'custom:firstname': value.firstName,
                                    'custom:lastname': value.lastName,
                                    'custom:company': value.company,
                                }
                            }

                            let result = await Auth.updateUserAttributes(user, authAttributes.attributes);

                            if (result === 'SUCCESS') {
                                Auth.currentAuthenticatedUser({
                                    bypassCache: true
                                })
                                    .then(authuser => {
                                        props.setAuthUser(authuser);
                                        Notify("Profile Updated Successfully");
                                    });
                            }

                            await Auth.changePassword(user, value.password, value.passwordConfirmation)
                            .then(data => console.log(data))
                            .catch(e => {
                                console.log(e);
                                Notify("Password not updated. Please check your old password", 'warning');
                              });

                            setSubmitting(false)
                            setLoading(false);
                            handleClose();
                            

                        } catch (err) {
                            console.log(err);
                            setStatus({ success: false })
                            setErrors({ submit: err.message });
                            setSubmitting(false)
                            setLoading(false);

                        }
                    }}
                >
                    {({ errors,
                        values,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        touched,
                    }) => (

                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        error={Boolean(touched.firstName && errors.firstName)}
                                        fullWidth
                                        helperText={touched.firstName && errors.firstName}
                                        label="First Name"
                                        margin="normal"
                                        name="firstName"
                                        type="text"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        fullWidth
                                        helperText={touched.lastName && errors.lastName}
                                        label="Last Name"
                                        margin="normal"
                                        name="lastName"
                                        type="text"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(touched.company && errors.company)}
                                        fullWidth
                                        helperText={touched.company && errors.company}
                                        label="Company (optional)"
                                        margin="normal"
                                        name="company"
                                        type="text"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.company}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        type="text"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(touched.password && errors.password)}
                                        fullWidth
                                        helperText={touched.password && errors.password}
                                        label="Old Password"
                                        margin="normal"
                                        name="password"
                                        type={showPasswords ? 'text' : 'password'}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                                        fullWidth
                                        helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                        label="New Password"
                                        margin="normal"
                                        name="passwordConfirmation"
                                        type={showPasswords ? 'text' : 'password'}
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.passwordConfirmation}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <div style={{ float: 'right' }}>
                                        <Typography variant="span" style={{ color: 'white', fontWeight: 600, marginRight: 5 }}>
                                            Show Passwords
                                        </Typography>
                                        <Switch
                                            onClick={() => handleShowPasswords(!showPasswords)}
                                            color="secondary"
                                            size="small"
                                            labelPlacement="start"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {errors.submit && (
                                        <Box sx={{
                                            mt: 3
                                        }}>
                                            <FormHelperText error>
                                                <h2>{errors.submit}</h2>
                                            </FormHelperText>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="primary"
                                        fullWidth
                                        size="large"
                                        color="primary"
                                        bgcolor="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Update Profile {loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
                <p style={{ marginTop: 25 }}>
                    <h4>Password Policy</h4>
                    Passwords must:
                    <ul>
                        <li>Contain minimum of eight(8) characters</li>
                        <li>Contain at least one(1) number</li>
                        <li>Contain at least one(1) uppercase character</li>
                    </ul>
                </p>
                <Divider />
            </Box >
        );
    }

    return (
        <div>
            <ListItem disablePadding onClick={() => props.handleMenuClose()}>
                <ListItemButton onClick={handleClickOpen}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faUser} />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </ListItemButton>
            </ListItem>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="save-playlist-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ border: '1px solid #7f7f7f' }}>
                    {renderPlaylistForm()}
                </DialogContent>
            </Dialog>
        </div>
    );
}

MyAccount.propTypes = {
    setAuthUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
})

export default connect(mapStateToProps, { setAuthUser })(MyAccount);

