import {
    UPDATE_USER_THEME,
    UPDATE_AUTH_STATUS,
    UPDATE_AUTHENTICATING,
    SET_AUTH_USER,
    SET_AUTH_GROUPS,
    UPDATE_USER_FILTERS,
    CLEAR_USER_FILTERS,
    SWITCH_PLAYER_MODE,
    UPDATE_USER_VOCAL_TYPE,
    UPDATE_USER_BPM,
    UPDATE_USER_DURATION,
    UPDATE_CURRENT_ROUTE,
    ADD_TO_PLAYLIST,
    LOAD_LOCAL_PLAYLIST,
    ADD_TO_SHOPPING_CART,
    REMOVE_FROM_SHOPPING_CART,
    REMOVE_FROM_PLAYLIST,
    UPDATE_PLAYLIST_COUNTS,
    FETCH_DEFAULT_PLAYLIST,
    CLEAR_DEFAULT_PLAYLIST,
    FETCH_SAVED_PLAYLISTS,
    UPDATE_SAVED_PLAYLIST_COUNT,
    UPDATE_LOCAL_PLAYLIST,
    UPDATE_FILTER_DRAWER_STATUS,
    UPDATE_FORGOT_PASSWORD_EMAIL,
    UPDATE_PLAYLIST_VISIBLE,
    UPDATE_AFFILIATE_ERROR
} from '../Actions/types';

const initialState = {
    currentTheme: 'dark',
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    usergroups: [],
    isAdmin: false,
    isPartner: false,
    partner: '',
    userMoods: [],
    userStyles: [],
    userInstruments: [],
    userVocalType: false,
    userBpm: false,
    userDuration: false,
    userPlaylist: [],
    defaultPlaylist: [],
    savedPlaylists: {},
    userShoppingCart: [],
    audioPlayerMode: false,
    currentRoute: ['Dashboard'],
    filterDrawerStatus: false,
    savedPlaylistCount: 0,
    forgotPasswordEmail: '',
    playlistCounts: [],
    playlistVisible: true,
    affiliateError: {error:false, type: '', message:''}
}

const userReducers = (state = null, action) => {
    switch (action.type) {

        case UPDATE_PLAYLIST_VISIBLE:
            return {
                ...state,
                playlistVisible: action.payload
            }

        case UPDATE_FORGOT_PASSWORD_EMAIL:
            return {
                ...state,
                forgotPasswordEmail: action.payload
            }

        case UPDATE_AUTH_STATUS:
            return {
                ...state,
                isAuthenticated: action.payload
            }

        case UPDATE_AUTHENTICATING:

            return {
                ...state,
                isAuthenticating: action.payload
            }

        case UPDATE_SAVED_PLAYLIST_COUNT:

            return {
                ...state,
                savedPlaylistCount: action.payload
            }

        case CLEAR_DEFAULT_PLAYLIST:

            return {
                ...state,
                defaultPlaylist: []
            }            

        case FETCH_DEFAULT_PLAYLIST:

            return {
                ...state,
                defaultPlaylist: action.payload
            }

        case FETCH_SAVED_PLAYLISTS:

            return {
                ...state,
                savedPlaylists: action.payload
            }

        case SET_AUTH_USER:

            return {
                ...state,
                user: action.payload
            }

        case SET_AUTH_GROUPS:
            let isadmin = action.payload.includes('admin') ? true : false;
            // console.log(isadmin)
            return {
                ...state,
                usergroups: action.payload,
                isAdmin: isadmin
            }

        case UPDATE_USER_FILTERS:

            return {
                ...state,
                userFilters: action.payload
            }

        case CLEAR_USER_FILTERS:

            return {
                ...state,
                userMoods: [],
                userStyles: [],
                userInstruments: [],
                userVocalType: false,
                userBpm: false,
                userDuration: false,
                userPlaylist: []
            }

        case UPDATE_USER_VOCAL_TYPE:
            return {
                ...state,
                userVocalType: action.payload
            }

        case UPDATE_USER_BPM:
            return {
                ...state,
                userBpm: action.payload
            }

        case UPDATE_USER_DURATION:
            return {
                ...state,
                userDuration: action.payload
            }


        case SWITCH_PLAYER_MODE:
            return {
                ...state,
                audioPlayerMode: action.payload
            }

        case UPDATE_CURRENT_ROUTE:
            return {
                ...state,
                currentRoute: action.payload
            }

        case ADD_TO_PLAYLIST:
            //var newplaylist = [...state.userPlaylist, action.payload];
            return {
                ...state,
                //userPlaylist: newplaylist
            }

        case LOAD_LOCAL_PLAYLIST:
            return {
                ...state,
                userPlaylist: action.payload
            }

        case ADD_TO_SHOPPING_CART:
            var newCart = [...state.userShoppingCart, action.payload];
            return {
                ...state,
                userShoppingCart: newCart
            }

        case REMOVE_FROM_SHOPPING_CART:
            var currentSc = state.userShoppingCart;
            for (var i = 0; i < currentSc.length; i++) {
                console.log(currentSc[i].id);
                if (currentSc[i].id === action.payload) {
                    currentSc.splice(i, 1);
                }
            }
            return {
                ...state,
                userShoppingCart: currentSc
            }


        case REMOVE_FROM_PLAYLIST:

            var currentPlaylist = state.userPlaylist

            for (i = 0; i < currentPlaylist.length; i++) {
                if (currentPlaylist[i] === action.payload) {
                    currentPlaylist.splice(i, 1);
                }
            }

            return {
                ...state,
                userPlaylist: currentPlaylist
            }


        case UPDATE_LOCAL_PLAYLIST:
            return {
                ...state,
                userPlaylist: action.payload
            }

        case UPDATE_PLAYLIST_COUNTS:

            return {
                ...state,
                playlistCounts: action.payload
            }

        case UPDATE_FILTER_DRAWER_STATUS:
            return {
                ...state,
                filterDrawerStatus: action.payload
            }

        case UPDATE_USER_THEME:
            return {
                ...state,
                currentTheme: action.payload
            }

        case UPDATE_AFFILIATE_ERROR:   
            return {
                ...state,
                affiliateError: action.payload
            }


        default:
            return state;
    }
}

export default userReducers;

