import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Formik } from "formik";
import * as Yup from "yup";
import { saveDefaultPlaylist, updatePlaylistFromLocal, fetchSavedPlaylists, loadLocalPlaylist } from '../../../../FlikTraxStore/Actions/userActions';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { Button, TextField } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Navigate } from 'react-router';
library.add(faCompactDisc);


const SavePlaylistDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const [removeDefault, setRemoveDefault] = React.useState(false);

  const handleRemoveDefaultChange = (event) => {
    setRemoveDefault(event.target.checked);
  };

  const { enqueueSnackbar } = useSnackbar();

  const Notify = (notification) => {
    enqueueSnackbar(notification, { variant: "success" });
  }

  const navigate = useNavigate();


  const renderPlaylistForm = () => {

    return (
      <Formik

        initialValues={{
          title: "",
        }}
        validationSchema={
          Yup.object().shape({
            title: Yup.string()
              // .min(4)
              // .max(50)
              .required('Playlist title is required'),
          })}
        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            setSubmitting(true);
            try {
              dispatch(saveDefaultPlaylist(value.title, removeDefault));
              dispatch(fetchSavedPlaylists());
              setStatus({ success: false })
              setLoading(false);
              setSubmitting(false);
              dispatch(updatePlaylistFromLocal());
              dispatch(loadLocalPlaylist());
              Notify(value.title + " playlist saved successfully!");
              setOpen(false);
              navigate('/saved-playlists');

            } catch (err) {
              console.log(err);
              setStatus({ success: false })
              setErrors({ submit: err.message });
              setSubmitting(false)
              setLoading(false);
            }

          } catch (err) {
            console.log(err);
            setStatus({ success: false })
            setErrors({ submit: err.message });
            setSubmitting(false)
            setLoading(false);
          }
        }}
      >
        {({ errors,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  helperText={touched.title && errors.title}
                  label="Playlist Title"
                  margin="normal"
                  name="title"
                  type="text"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={removeDefault}
                    onChange={handleRemoveDefaultChange}
                    id="removeDefault"
                    name="removeDefault"
                    color="secondary"
                  />}
                  label="Clear Default Playlist On Save"
                />
              </Grid>
              <Grid item xs={12} >
                <Button
                  variant="primary"
                  fullWidth
                  size="large"
                  color="primary"
                  bgcolor="primary"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Save Playlist{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                </Button>

              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    );
  }

  return (
    <div>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <SaveAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Save Playlist" />
        </ListItemButton>
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" component="div">
            <SaveAltOutlinedIcon style={{ marginRight: 5, marginTop: 5 }} /> Save Playlist
            <img style={{ maxHeight: 50, marginTop: 10, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          {renderPlaylistForm()}
        </DialogContent>
      </Dialog>
    </div>
  );
}

SavePlaylistDialog.propTypes = {
  saveDefaultPlaylist: PropTypes.func.isRequired,
  updatePlaylistFromLocal: PropTypes.func.isRequired,
  fetchSavedPlaylists: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  api: state.api,
  user: state.user,
})

export default connect(mapStateToProps,
  {
    saveDefaultPlaylist,
    updatePlaylistFromLocal,
    fetchSavedPlaylists
  })(SavePlaylistDialog);

