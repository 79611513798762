import {
    FETCH_API_DATA,
    FETCH_FEATURED_PLAYLIST,
    SWITCH_MASTER_MODE,
    SWITCH_API_URL,
    SWITCH_API_PAGE,
    UPDATE_TRACK_PAGE_TITLE,
    IS_PLAYLIST_PAGE,
    SET_ACTIVE_MIX_TRACK,
    OPEN_SPLITS,
    CLOSE_SPLITS,
    SET_CURRENT_SEARCH,
    SET_CURRENT_SEARCH_TERM,
    SET_VOCAL_FILTER
} from './types';

import axios from 'axios';
import apiconfig from '../../../config/api-config.js';
import FlikTraxStore from '../FlikTraxStore';
import { updateAutoPlay  } from './audioPlayerActions';

function getUserFilters() {
    // var styles = FlikTraxStore.getState().user.userStyles;
    // var moods = FlikTraxStore.getState().user.userMoods;
    // var instruments = FlikTraxStore.getState().user.userInstruments;
    // var vocaltype = FlikTraxStore.getState().user.userVocalType;
    // var duration = FlikTraxStore.getState().user.userDuration;
    // var bpm = FlikTraxStore.getState().user.userBpm;
    // var retFilters = {
    //     styles: styles,
    //     moods: moods,
    //     instruments: instruments,
    //     vocaltype: vocaltype,
    //     duration: duration,
    //     bpm: bpm
    // }
    return {
        moods: [],
        styles: [],
        instruments: [],
        vocaltype: FlikTraxStore.getState().api.vocalFilter,
        bpm: false,
        duration: false,
    }
}

export const setCurrentSearch = (trackid) => dispatch => {

    if (trackid === null) {
        dispatch({
            type: SET_CURRENT_SEARCH,
            payload: null
        });
        return;
    }

    axios({
        baseURL: 'https://api.fliktrax.com/track/' + trackid,
        method: 'get',
        auth: {
            username: apiconfig.api.APIUSERNAME,
            password: apiconfig.api.APIPASSWORD
        },
        headers: {
            "Content-Type": "application/hal+json"
        },
        //timeout: 2000,
    })

        .then(response => {
            dispatch({
                type: SET_CURRENT_SEARCH,
                payload: response.data
            });
        });
}

export const setCurrentSearchTerm = (type, searchterm) => dispatch => {
    dispatch({
        type: SET_CURRENT_SEARCH_TERM,
        payload: { type: type, searchterm: searchterm }
    })
}

export const setVocalFilter = (filter) => dispatch => {
    dispatch({
        type: SET_VOCAL_FILTER,
        payload: filter
    })
}

export const fetchApiData = () => {

    var url = FlikTraxStore.getState().api.apiUrl;
    var page = FlikTraxStore.getState().api.apiPage;
    var axiosUrl = apiconfig.api.APIURL + url + '&page=' + page;
    var masterMode = FlikTraxStore.getState().api.masterMode;
    var filters = getUserFilters();


    switch (masterMode) {

        case 'categories':
            axiosUrl = apiconfig.api.APIURL + url + '?page=' + page;
            break;

        case 'trackdetail':
            axiosUrl = url + '&page=' + page;
            break;

        default:
            axiosUrl = apiconfig.api.APIURL + url + '&page=' + page;
    }

    return (dispatch) => {

        dispatch(apiIsLoading(true))

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            params: {
                filters: filters
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })

            .then(response => {
                dispatch({
                    type: FETCH_API_DATA,
                    payload: response.data
                });

                var oEmbed = Object.values(response.data._embedded);
                var apTracks = oEmbed[0];
                FlikTraxStore.dispatch(updateAutoPlay(apTracks));
            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
};


export const fetchFeaturedPlaylists = () => {
    var playlistIds = FlikTraxStore.getState().user && FlikTraxStore.getState().user.userPlaylist;
    var truncatedPlaylist = playlistIds && Object.values(playlistIds).slice(0, 5).join('","');
    var axiosUrl = 'https://api.fliktrax.com/featuredplaylist';

    return (dispatch) => {

        axios({
            baseURL: axiosUrl,
            method: 'get',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
            //timeout: 2000,
        })

            .then(response => {
               
                dispatch({
                    type: FETCH_FEATURED_PLAYLIST,
                    payload: response.data._embedded.featuredplaylist
                });
            })
            .then((response) => dispatch(apiIsLoading(false)))
            .catch((error) => dispatch(apiIsLoading(false)))
    }
};


export const switchMasterMode = (mode) => dispatch => {
    dispatch({
        type: SWITCH_MASTER_MODE,
        payload: mode
    })
}

export const switchApiPage = (page) => dispatch => {
    dispatch({
        type: SWITCH_API_PAGE,
        payload: page
    })
}

export const switchApiUrl = (type) => dispatch => {
    dispatch({
        type: SWITCH_API_URL,
        payload: type
    })
}

export const updateTrackPageTitle = (title) => dispatch => {
    dispatch({
        type: UPDATE_TRACK_PAGE_TITLE,
        payload: title
    })
}

export const apiIsLoading = (bool) => {
    return {
        type: "AXIOS_IS_LOADING",
        payload: {
            isLoading: bool
        }
    };
};

export const modalIsLoading = (bool) => {
    return {
        type: "MODAL_IS_LOADING",
        payload: {
            modalLoading: bool
        }
    };
};

export const updateIsPlaylist = (isplaylist) => dispatch => {
    dispatch({
        type: IS_PLAYLIST_PAGE,
        payload: isplaylist
    })
}

export const setActiveMixTrack = (trackid) => dispatch => {
    dispatch({
        type: SET_ACTIVE_MIX_TRACK,
        payload: trackid
    })
}

export const getTrackInfoLink = (trackId, isCustom = false) => {

    var userAttributes = FlikTraxStore.getState().user.user.attributes;
    var userIsPartner = userAttributes['custom:ispartner'];

    return async (dispatch) => {

        var sndData = {}

        switch (userIsPartner) {
            case true:
                var userPartner = userAttributes['custom:partner'];
                sndData = {
                    trackid: trackId,
                    service: 'pdf-service',
                    iscustom: isCustom,
                    partner: userPartner
                }
                break;

            default:
                sndData = {
                    trackid: trackId,
                    service: 'pdf-service',
                    iscustom: isCustom
                }
        }

        await axios({
            baseURL: 'https://api.fliktrax.com/services',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: sndData,
            headers: {
                "Content-Type": "application/hal+json"
            },
        })
            .then(response => {
                let statuscode = response.data.code
                switch (statuscode) {
                    case 200:
                        window.open(response.data.pdfUrl, '_blank');
                        break;

                    default:
                        dispatch(apiIsLoading(true))
                        setTimeout(() => {
                            window.open(response.data.pdfUrl, '_blank');
                            dispatch(apiIsLoading(false))
                        }, 3000);
                }
            })
            .catch((error) => console.log(error))
    }
};

export const openSplits = (trackId) => dispatch => {
    dispatch({
        type: OPEN_SPLITS,
        payload: { open: true, trackid: trackId }
    });
}

export const closeSplits = () => dispatch => {
    dispatch({
        type: CLOSE_SPLITS,
        payload: false
    })
}


export const fetchPublishingData = (trackId) => {

    return async (dispatch) => {

        dispatch(modalIsLoading(true));

        await axios({
            baseURL: 'https://api.fliktrax.com/services',
            method: 'post',
            auth: {
                username: apiconfig.api.APIUSERNAME,
                password: apiconfig.api.APIPASSWORD
            },
            data: {
                iscustom: true,
                trackid: trackId,
                service: 'publishing-splits',
                partner: "janson"
            },
            headers: {
                "Content-Type": "application/hal+json"
            },
        })
            .then(response => {
                dispatch({
                    type: OPEN_SPLITS,
                    payload: response.data
                });

                dispatch(modalIsLoading(false))
            })
            .catch((error) => console.log(error))
    }

}

