import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { updateAuthStatus, setAuthUser, updateAuthenticating, smsNotify } from '../FlikTraxStore/Actions/userActions';
import { apiIsLoading } from '../FlikTraxStore/Actions/apiActions';
import LoadingBackdrop from '../Utilities/LoadingBackdrop';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField, FormHelperText } from '@mui/material';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCompactDisc);

const style = {
    position: 'relative',
    top: '53%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '55%',
    bgcolor: '#272727',
    border: '1px solid #fff',
    boxShadow: 1,
    p: 3,
}

const Login = (props) => {

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {

        scrollToTop();

        if (props.user && props.user.isAuthenticated === true) {
            navigate('/');
        }

    }, [props.user, navigate]);

    const [form, setForm] = React.useState('login');

    const [loading, setLoading] = React.useState(false);

    const [showPasswords, handleShowPasswords] = React.useState(false);

    const [codeSent, setCodeSent] = React.useState(false);

    const [userAttributes, setUserAttributes] = React.useState({
        vcode: '',
        username: '',
        password: '',
    });

    const redirectPage = () => {
        window.location.replace("/");
    }

    const renderForgotForm = () => {
        const resendVerifyCode = () => {
            Auth.resendSignUp(userAttributes.username).then(() => {
                setCodeSent(true);
            }).catch(err => {
                setCodeSent(false);
            });

        }
        return (
            <Container className="ft-verify" maxWidth="md">
                <LoadingBackdrop open={loading} />
                <Box sx={style} >
                    <img style={{ maxHeight: 100, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                    <h1>Reset Password</h1>
                    Please enter the email address associated with your account and we'll email you verification code to reset your password.
                    <Formik
                        initialValues={{
                            email: ""
                        }}
                        validationSchema={
                            Yup.object().shape({
                                email: Yup.string()
                                    .email('Must be a valid email.')
                                    .min(4)
                                    .max(255)
                                    .required('Email is required.')
                            })}
                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setLoading(true);
                                setStatus({ success: true });
                                setSubmitting(true);

                                try {
                                    let authUsername = value.email;

                                    let authAttributes = {
                                        'username': authUsername,
                                    }

                                    setUserAttributes(authAttributes);
                                    await Auth.forgotPassword(value.email);
                                    setSubmitting(false)
                                    setLoading(false);
                                    setForm('verify');
                                } catch (err) {
                                    console.log(err);
                                    setStatus({ success: false })
                                    setErrors({ submit: err.message });
                                    setSubmitting(false)
                                    setLoading(false);
                                }

                            } catch (err) {
                                console.log(err);
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email"
                                            margin="normal"
                                            name="email"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        {errors.submit && (
                                            <Box sx={{
                                                mt: 3
                                            }}>
                                                <FormHelperText error>
                                                    <h2>{errors.submit}</h2>
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="primary"
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            bgcolor="primary"
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            Verify{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    <p style={{ marginTop: 50 }}>
                        <Link to="#" style={{ textDecoration: 'underline', marginLeft: 10 }} onClick={() => setForm('login')} >
                            Back to Login Form
                        </Link>
                    </p>
                </Box>
            </Container>
        );
    }

    const renderResetForm = () => {

        const resendVerifyCode = () => {
            Auth.resendSignUp(userAttributes.username).then(() => {
                setCodeSent(true);
            }).catch(err => {
                setCodeSent(false);
            });
        }

        return (
            <Container className="ft-verify" maxWidth="md">
                <LoadingBackdrop open={loading} />
                <Box sx={style} >
                    <img style={{ maxHeight: 100, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                    <h1>Reset Password</h1>
                    Please check your email for the verification code sent to {userAttributes.username}
                    <Formik
                        initialValues={{
                            vcode: "",
                            email: "",
                            password: "",
                            passwordConfirmation: ""
                        }}
                        validationSchema={
                            Yup.object().shape({
                                vcode: Yup.string()
                                    .min(4)
                                    .max(255)
                                    .required('Verification code is required.'),
                                password: Yup.string()
                                    .min(8, 'Must include at least 8 characters.')
                                    .max(25, 'Passwords may not exceed 25 characters.')
                                    .required('Password is required.')
                                    .matches(
                                        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                                        "Must Contain st least 8 characters, one uppercase and one number."
                                    ),
                                passwordConfirmation: Yup.string()
                                    .required('Password Confirmation is required.')
                                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                            })}
                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setLoading(true);
                                setSubmitting(true);

                                console.log(value);

                                await Auth.forgotPasswordSubmit(userAttributes.username, value.vcode, value.password);

                                try {
                                    const user = await Auth.signIn(userAttributes.username, value.password);
                                    props.updateAuthStatus(true);
                                    props.setAuthUser(user);
                                    var msg = userAttributes.username + " Reset Their Password";
                                    props.smsNotify("TVPros Password Reset", msg);

                                    navigate('/');

                                } catch (err) {
                                    console.log(err);
                                    setStatus({ success: false })
                                    setErrors({ submit: err.message });
                                    setSubmitting(false)
                                    setLoading(false);
                                }

                            } catch (err) {
                                console.log(err);
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.vcode && errors.vcode)}
                                            fullWidth
                                            helperText={touched.vcode && errors.vcode}
                                            label="Verification Code"
                                            margin="normal"
                                            name="vcode"
                                            type="text"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.vcode}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.password && errors.password)}
                                            fullWidth
                                            helperText={touched.password && errors.password}
                                            label="Password"
                                            margin="normal"
                                            name="password"
                                            type={showPasswords ? 'text' : 'password'}
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                                            fullWidth
                                            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                            label="Confirm Password"
                                            margin="normal"
                                            name="passwordConfirmation"
                                            type={showPasswords ? 'text' : 'password'}
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.passwordConfirmation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div style={{ float: 'right' }}>
                                            <Typography variant="span" style={{ color: 'white', fontWeight: 600, marginRight: 5 }}>
                                                Show Passwords
                                            </Typography>
                                            <Switch
                                                onClick={() => handleShowPasswords(!showPasswords)}
                                                color="secondary"
                                                size="small"
                                                labelPlacement="start"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} >
                                        {errors.submit && (
                                            <Box sx={{
                                                mt: 3
                                            }}>
                                                <FormHelperText error>
                                                    <h2>{errors.submit}</h2>
                                                </FormHelperText>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: 35 }} >

                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button
                                            variant="primary"
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            bgcolor="primary"
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            Reset Password{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                        </Button>
                                        {errors.submit && (
                                            <React.Fragment>
                                                <Divider style={{ color: 'white', margin: 25 }} />
                                                <Button
                                                    onClick={() => resendVerifyCode()}
                                                    variant="primary"
                                                    fullWidth
                                                    size="large"
                                                    color="primary"
                                                    bgcolor="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Resend Code{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                                </Button>
                                                {codeSent && (
                                                    <FormHelperText success>
                                                        <h2>Code sent successfully</h2>
                                                    </FormHelperText>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                    <p style={{ marginTop: 25 }}>
                        <h4>Password Policy</h4>
                        Passwords must:
                        <ul>
                            <li>Contain minimum of eight(8) characters</li>
                            <li>Contain at least one(1) number</li>
                            <li>Contain at least one(1) uppercase character</li>
                        </ul>
                    </p>
                    <Divider />
                    <p style={{ marginTop: 25 }}>
                        Already have an account?
                        <Link style={{ textDecoration: 'underline', marginLeft: 10 }} to="/login" >
                            Login here.
                        </Link>
                    </p>
                </Box>
            </Container >
        );
    }

    const renderLoginForm = () => {

        return (

            <Container className="ft-container" maxWidth="md">
                <LoadingBackdrop open={loading} />
                <Box sx={style} >
                    <img style={{ maxHeight: 100, float: 'right', marginRight: 25 }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                    <Typography variant="h4" style={{ color: 'white', fontWeight: 300 }}>
                        Login
                    </Typography>
                    <Typography  style={{ color: 'white', marginTop:75 }}>
                        Welcome to Janson Music, offering the highest quality production music for your media.
                    </Typography>
                    <Typography  style={{ color: 'white', marginTop:25, marginBottom:25 }}>
                        Janson Media has partnered with FlikTrax to offer our content partners a carefully curated, exclusive platform that offers the highest quality audio for your original productions. For details and more information, see our <a  href="/about" style={{color: '#c71b21', textDecoration: 'underline'}}>about page</a>.
                    </Typography>

                    <Formik
                        initialValues={{
                            username: "",
                            email: "",
                            password: "",

                        }}
                        validationSchema={
                            Yup.object().shape({
                                email: Yup.string()
                                    .email('Must be a valid email.')
                                    .max(255)
                                    .required('Email is required.'),
                                password: Yup.string()
                                    .min(6, 'Must include at least 8 characters.')
                                    .max(25, 'We KNOW your password isn' + 't more than 25 characters long.')
                                    .required('A password is required.'),
                            })}
                        onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setLoading(true);
                                const user = await Auth.signIn(value.email, value.password);
                                props.updateAuthStatus(true);
                                props.setAuthUser(user);
                                var msg = "New Janson Media Login: " + user.attributes.email;
                                props.smsNotify("New Janson Media Login: " + user.attributes.email, msg);
                                setTimeout(() => {
                                    redirectPage();
                                }, 1000);
                            } catch (err) {
                                console.log(err)
                                console.log(err);
                                setStatus({ success: false })
                                setErrors({ submit: err.message });
                                setSubmitting(false)
                                setLoading(false);
                            }
                        }}
                    >
                        {({ errors,
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email"
                                    margin="normal"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}

                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    type={showPasswords ? 'text' : 'password'}
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}


                                />
                                <div style={{ float: 'right', padding: 20 }}>
                                    <Typography variant="span" style={{ color: 'white', fontWeight: 600, marginRight: 5 }}>
                                        Show Password
                                    </Typography>
                                    <Switch
                                        onClick={() => handleShowPasswords(!showPasswords)}
                                        color="secondary"
                                        size="small"
                                        labelPlacement="start"
                                    />
                                </div>
                                {errors.submit && (
                                    <Box sx={{
                                        mt: 3
                                    }}>
                                        <FormHelperText style={{ fontSize: '1.1em' }} error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}
                                <Button
                                    variant="primary"
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    bgcolor="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Login{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                </Button>

                            </form>
                        )}
                    </Formik>
                    <p style={{ marginTop: 25 }}>
                        <h4>Password Policy</h4>
                        Password must:
                        <ul>
                            <li>Contain minimum of eight(8) characters</li>
                            <li>Contain at least one(1) number</li>
                            <li>Contain at least one(1) uppercase character</li>
                        </ul>
                    </p>
                    <Divider />
                    <p style={{ marginTop: 25 }}>
                        Forgot your password?
                        <Link style={{ textDecoration: 'underline', marginLeft: 10 }} to onClick={() => setForm('forgot')} >
                            Reset your password.
                        </Link>
                    </p>
                    <p style={{ marginTop: 25 }}>
                        Don't have an account?
                        <Link style={{ textDecoration: 'underline', marginLeft: 10 }} to="/register" >
                            Request Access.
                        </Link>
                    </p>
                </Box>
            </Container>
        )
    }



    switch (form) {
        case 'forgot':
            return renderForgotForm();

        case 'verify':
            return renderResetForm();

        default:
            try {
                localStorage.setItem('lsTest', 'test');
                localStorage.removeItem('lsTest');
                return renderLoginForm();
            } catch (e) {
                alert("localStorage is not supported by your browser. Please disable private browsing or upgrade to a modern browser.");
            }

    }

}

Login.propTypes = {
    updateAuthStatus: PropTypes.func.isRequired,
    setAuthUser: PropTypes.func.isRequired,
    updateAuthenticating: PropTypes.func.isRequired,
    smsNotify: PropTypes.func.isRequired,
    apiIsLoading: PropTypes.func.isRequired,
}


function mapStateToProps(state) {
    return {
        user: state.user,
        api: state.api
    };
}

export default connect(mapStateToProps, { updateAuthStatus, setAuthUser, updateAuthenticating, apiIsLoading, smsNotify })(Login);