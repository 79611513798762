import React, { useEffect } from 'react';
import BrandLogos from './BrandLogos';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Home = (props) => {


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    useEffect(() => {

        scrollToTop();

    }, []);

    return (
        <Container className="ft-container" maxWidth="md">
            <h1>About Janson Music</h1>
            <Divider style={{ marginBottom: 25 }} />
            {/* <h3>Your License To "HitItHard"</h3> */}
            <Box>
                <Parallax
                    bgImage="/images/home/valhalla.jpg"
                    bgImageStyle={{ opacity: '.3' }}
                    strength={500}
                >
                    <Box sx={{ flexGrow: 1, p: 4 }}  >
                        <Typography variant="h6" pb={2} style={{ color: 'white', fontWeight: 300 }}>
                            Janson Media has partnered with FlikTrax Music to offer our content partners a carefully curated, exclusive platform that offers the highest quality audio for your original productions.
                        </Typography>

                        <Typography variant="h6" pb={2}  style={{ color: 'white', fontWeight: 300 }}>
                            An award winning team of experienced composers are contributing to JANSON MUSIC on a daily basis, with selections in all contemporary styles. You may download one track at a time, or create your own Playlists, save them and download them later with just one click. Composer/Publishing information is available to download in every track’s “info” button.
                        </Typography>

                        <Typography variant="h6" pb={2}  style={{ color: 'white', fontWeight: 300 }}>
                            All tracks on JANSON MUSIC are available to you by <em>gratis</em> license agreement, for use in your original content designed for TV or Digital distribution. In return for the use of this outstanding FREE music resource to enhance your productions, our only requirement is that you file proper music cue sheets. Our team is happy to assist with this process. More information is available here, along with a sample music cue sheet.
                        </Typography>

                        <Typography variant="h6" pb={2}  style={{ color: 'white', fontWeight: 300 }}>
                            The goal of our new platform is to help our content partners locate the exact type of music needed for their productions, quickly. If you need help with a specialized search or have any other questions, don’t hesitate to <a rel="noreferrer" target="_blank" href="mailto:info@fliktrax.com" style={{color: '#c71b21', textDecoration: 'underline'}}>contact us</a>.
                        </Typography>
                    </Box>
                </Parallax>                
            </Box>
            {/* <h1>Curriculum Vitae</h1>
            <Divider style={{ marginBottom: 25 }} />
            <BrandLogos /> */}
        </Container >
    )
}

export default Home;