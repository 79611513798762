import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Switch
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { updateUserTheme } from './components/FlikTraxStore/Actions/userActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Layout from './components/Layout/Layout';
import Collections from './components/Dashboards/Collections';
import NotFound from './components/NotFound';

function ThemedRoutes(props) {

  const darkTheme = createTheme({
    typography: {
      "fontFamily": `"Raleway", sans-serif`,
      "fontSize": 13,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    palette: {
      mode: props.user && props.user.currentTheme !== null ? props.user.currentTheme : 'light',
      primary: {
        main: '#272727',
      },
      secondary: {
        main: '#ff3705',
      },
    },

    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: "#272727",
            color: "white",
          },
        }
      },
      MuiInputLabel: {

        root: {
          color: "white",
        },

      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "white",
          },
          primary: {
            backgroundColor: "#ff3705",
            '&:hover': {
              backgroundColor: "#f00",
            },
            color: "white",
            fontWeight: 600
          },  // contained primary
          secondary: {
            backgroundColor: "green",
            color: "white",
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={2000}
      >
        {/* <CssBaseline /> */}
        <BrowserRouter>
          <Layout />
          <Routes>
            <Route
              path='/'
            />
            <Route
              path='/about'
            />
            <Route
              path='/custom-music'
            />
            <Route
              path='/login'
            />
            <Route
              path='/register'
            />
            <Route
              path='/request'
            />

            <Route
              path='/collection'
            />

            <Route
              path='/collections'
            />

            <Route
              path='/categories'
            />

            <Route
              path='/category'
            />

            <Route
              path='/support'
            />
            <Route
              path='/my-playlist'
            />
            <Route
              path='/saved-playlists'
            />
            <Route
              path='/shared-playlist'
            />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider >
  );
}

function mapStateToProps(state) {
  return { user: state.user };
}
ThemedRoutes.propTypes = {
  updateUserTheme: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { updateUserTheme })(ThemedRoutes);