import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import CollectionTable from './TrackTables/CollectionTable';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const Collection = (props) => {

    const navigate = useNavigate();

    const collectionId = (new URLSearchParams(window.location.search)).get("collectionId");

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

      useEffect(() => {

        scrollToTop();

        if (props.user && !props.user.isAuthenticated) {
            navigate('/login');
        }

    }, [navigate, props.user]);

    return (
        <Container className="ft-container" maxWidth="md">
            <CssBaseline />
            <Grid className="search-container" container spacing={2}>
                <Grid item xs={12}>
                    <CollectionTable scrollToTop={() =>scrollToTop()} collectionID={collectionId}  />
                </Grid>
            </Grid>

        </Container>
    );

}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps)(Collection);

