import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
var truncate = require('truncate');

const CategoryCard = (props) => {

    const navigate = useNavigate(); 

    const handleClick = () => {
        let destination = '/collection?collectionId=' + props.CardData.slug;    
        localStorage.setItem('categoryImage', props.CardData.image);
        localStorage.setItem('categoryTitle', props.CardData.title);
        navigate(destination);
    }

    return (
        <Card sx={{ maxWidth: 345 }} 
                    elevation={3}
                    onClick = {() => handleClick()}
                    style ={{ backgroundColor:"black" }}
        >
            <CardActionArea>
                <CardMedia
                    style={{ marginLeft: 5, width:130 }}
                    paperProps={{ backgroundColor:"black" }}
                    //height="150"
                    component="img"
                    image={props.CardData.image}
                    alt={props.CardData.title}
                />
                {/* <CardContent style={{maxHeight:35}}>
                        <Typography gutterBottom variant="subtitle2" component="span">
                            {truncate(this.props.CardData.title, 15)}
                        </Typography>
                    </CardContent> */}
            </CardActionArea>
        </Card>
    );

}

export default CategoryCard;


