const appRoutes =  {
	navigation: {
		search: {
			"title": "SEARCH",
			route: "/search",
			"icon": "search"
		},		

		categories: {
			"title": "CATEGORIES",
			"route": "/categories",
			"icon": "sitemap"
		},
		newreleases: {
			"title": "COLLECTIONS",
			"route": "/collections",
			"icon": "bell"
		},
	},
	playlists: {
		myplaylist: {
			"title": "MY PLAYLIST",
			"route": "/my-playlist",
			"icon": "th-list"
		},
		savedplaylists: {
			"title": "SAVED PLAYLISTS",
			"route": "/saved-playlists",
			"icon": "stream"
		},
	},
	company: {
		about: {
			"title": "ABOUT",
			"route": "/about",
			"icon": "address-card"
		},
		custommusic: {
			"title": "CUSTOM MUSIC",
			"route": "/custom-music",
			"icon": "guitar"
		},
		request: {
			"title": "REQUEST",
			"route": "/request",
			"icon": "bullhorn"
		},
		// support: {
		// 	"title": "SUPPORT",
		// 	route: "/support",
		// 	"icon": "question-circle"
		// },
	}
}

export default appRoutes;