import * as React from 'react';
import axios from 'axios';
import apiconfig from '../../../../../config/api-config.js';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Formik } from "formik";
import * as Yup from "yup";
import { saveDefaultPlaylist, updatePlaylistFromLocal, fetchSavedPlaylists } from '../../../../FlikTraxStore/Actions/userActions';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button, TextField } from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCompactDisc);


const SavePlaylistDialog = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const [removeDefault, setRemoveDefault] = React.useState(false);

    const handleRemoveDefaultChange = (event) => {
        setRemoveDefault(event.target.checked);
    };

    const { enqueueSnackbar } = useSnackbar();

    const Notify = (notification) => {
        enqueueSnackbar(notification, { variant: "success" });
    }

    const navigate = useNavigate();


    const renderSharePlaylistForm = () => {

        return (
            <Formik

                initialValues={{
                    fromname: props.user.user.attributes['custom:firstname'] + ' ' + props.user.user.attributes['custom:lastname'],
                    email: props.user.user.attributes.email,
                    playlistid: props.playlistID,
                    service: "ses-template-service",
                    template: "janson-media"
                }}
                validationSchema={
                    Yup.object().shape({
                        toemail: Yup.string()
                            .email('Must be a valid email.')
                            .max(255)
                            .required('Email is required.'),
                        comment: Yup.string()
                            .min(8, 'Must include at least 8 characters.')
                            .required('Comment is required.'),
                    })}
                onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setLoading(true);
                        setSubmitting(true);
                        try {
                            let axiosUrl = 'https://api.fliktrax.com/services';
                            axios({
                                baseURL: axiosUrl,
                                method: 'post',
                                auth: {
                                    username: apiconfig.api.APIUSERNAME,
                                    password: apiconfig.api.APIPASSWORD
                                },
                                data: {
                                    service: "ses-template-service",
                                    playlistid: props.playlistID,
                                    toemail:value.toemail,
                                    fromname: value.fromname,
                                    template: "janson-media",
                                    message: value.comment
                                },
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                                .then(response => {
                                    console.log(response.data);
                                });

                            setStatus({ success: false })
                            setLoading(false);
                            setSubmitting(false);
                            Notify(props.playlistName + "  shared successfully!");
                            setOpen(false);


                        } catch (err) {
                            console.log(err);
                            setStatus({ success: false })
                            setErrors({ submit: err.message });
                            setSubmitting(false)
                            setLoading(false);
                        }

                    } catch (err) {
                        console.log(err);
                        setStatus({ success: false })
                        setErrors({ submit: err.message });
                        setSubmitting(false)
                        setLoading(false);
                    }
                }}
            >
                {({ errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                    touched,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    error={Boolean(touched.fromname && errors.fromname)}
                                    fullWidth
                                    helperText={touched.fromname && errors.fromname}
                                    //label="Name"
                                    margin="normal"
                                    name="fromname"
                                    type="text"
                                    variant="outlined"
                                    value={props.user.user.attributes['custom:firstname'] + ' ' + props.user.user.attributes['custom:lastname']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true}
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    //label="Email"
                                    margin="normal"
                                    name="email"
                                    type="text"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={props.user.user.attributes.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(touched.toemail && errors.toemail)}
                                    fullWidth
                                    helperText={touched.toemail && errors.toemail}
                                    label="To Email"
                                    margin="normal"
                                    name="toemail"
                                    type="text"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.toemail}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(touched.comment && errors.comment)}
                                    fullWidth
                                    helperText={touched.comment && errors.comment}
                                    multiline={true}
                                    rows={5}
                                    label="Comment"
                                    margin="normal"
                                    name="comment"
                                    type="text"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.comment}
                                />
                            </Grid>
                            <Grid item xs={12} hidden={true}>
                                <TextField
                                    name="playlistid"
                                    type="hidden"
                                    value={props.playlistID}
                                />

                                <TextField
                                    name="service"
                                    type="hidden"
                                    value="ses-template-service"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Button
                                    variant="primary"
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    bgcolor="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    Share Playlist{loading === true && (<FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCompactDisc} size="2x" spin />)}
                                </Button>

                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        );
    }

    return (
        <div>
            <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Share Playlist"
                placement="top"
                arrow
            >
                <Button size="small" color="success" style={{ marginRight: 10 }}><ShareOutlinedIcon onClick={handleClickOpen} /></Button>
                {/* onClick={handleClickOpen */}
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" component="div">
                        <ShareOutlinedIcon style={{ marginRight: 5, marginTop: 5 }} /> Share Playlist
                        <img style={{ maxHeight: 50, marginTop: 10, float: 'right' }} src="/images/logos/janson-media-black.png" alt="TVPros" />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {renderSharePlaylistForm()}
                </DialogContent>
            </Dialog>
        </div>
    );
}

SavePlaylistDialog.propTypes = {
    saveDefaultPlaylist: PropTypes.func.isRequired,
    updatePlaylistFromLocal: PropTypes.func.isRequired,
    fetchSavedPlaylists: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    api: state.api,
    user: state.user,
})

export default connect(mapStateToProps,
    {
        saveDefaultPlaylist,
        updatePlaylistFromLocal,
        fetchSavedPlaylists
    })(SavePlaylistDialog);

