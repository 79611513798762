export const FETCH_API_DATA = 'FETCH_API_DATA';
export const SWITCH_MASTER_MODE = 'SWITCH_MASTER_MODE';
export const SWITCH_API_URL = 'SWITCH_API_URL';
export const SWITCH_API_PAGE = 'SWITCH_PAGE';
export const UPDATE_API_DATA = 'UPDATE_API_DATA';
export const UPDATE_PAGE_CHANGE = 'UPDATE_PAGE_CHANGE';
export const UPDATE_TRACK_PAGE_TITLE = 'UPDATE_TRACK_PAGE_TITLE';
export const FETCH_FILTER_DATA = 'FETCH_FILTER_DATA';
export const IS_PLAYLIST_PAGE = 'IS_PLAYLIST_PAGE';
export const GET_TRACK_INFO_LINK = 'GET_TRACK_INFO_LINK';
export const SET_ACTIVE_MIX_TRACK = 'GET_ACTIVE_MIX_TRACK';
export const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
export const UPDATE_AUTHENTICATING = 'UPDATE_AUTHENTICATING';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_GROUPS = 'SET_AUTH_GROUPS';
export const FETCH_USER_FILTERS = 'FETCH_USER_FILTERS';
export const CLEAR_USER_FILTERS = 'CLEAR_USER_FILTERS';
export const UPDATE_USER_FILTERS = 'UPDATE_USER_FILTERS';
export const SWITCH_PLAYER_MODE = 'SWITCH_PLAYER_MODE';
export const UPDATE_USER_VOCAL_TYPE = 'UPDATE_USER_VOCAL_TYPE';
export const UPDATE_USER_BPM = 'UPDATE_USER_BPM';
export const UPDATE_USER_DURATION = 'UPDATE_USER_DURATION';
export const UPDATE_CURRENT_ROUTE = 'UPDATE_CURRENT_ROUTE';
export const ADD_TO_PLAYLIST = 'ADD_TO_PLAYLIST';
export const LOAD_LOCAL_PLAYLIST = 'LOAD_LOCAL_PLAYLIST';
export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART';
export const GET_USER_SHOPPING_CART = 'GET_USER_SHOPPING_CART';
export const REMOVE_FROM_PLAYLIST = 'REMOVE_FROM_PLAYLIST';
export const CLEAR_PLAYLIST = 'CLEAR_PLAYLIST';
export const UPDATE_PLAYLIST_VISIBLE = 'UPDATE_PLAYLIST_VISIBLE';
export const DOWNLOAD_PLAYLIST = 'DOWNLOAD_PLAYLIST';
export const UPDATE_LOCAL_PLAYLIST = 'UPDATE_LOCAL_PLAYLIST';
export const UPDATE_FILTER_DRAWER_STATUS =  'UPDATE_FILTER_DRAWER_STATUS'; 
export const CLEAR_DEFAULT_PLAYLIST = 'CLEAR_DEFAULT_PLAYLIST';
export const FETCH_SAVED_PLAYLISTS = 'FETCH_SAVED_PLAYLISTS';
export const FETCH_DEFAULT_PLAYLIST = 'FETCH_DEFAULT_PLAYLIST';
export const UPDATE_SAVED_PLAYLIST_COUNT = 'UPDATE_PLAYLIST_COUNT';
export const UPDATE_PLAYLIST_COUNTS = 'UPDATE_PLAYLIST_COUNTS';
export const UPDATE_NOW_PLAYING = 'UPDATE_NOW_PLAYING';
export const UPDATE_FEATURED_PLAYING = 'UPDATE_FEATURED_PLAYING';
export const UPDATE_IS_PLAYING = 'UPDATE_IS_PLAYING';
export const UPDATE_IS_PAUSED = 'UPDATE_IS_PAUSED';  
export const UPDATE_POSITION = 'UPDATE_POSITION'; 
export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const UPDATE_AXIOS_LOADING = 'UPDATE_AXIOS_LOADING'; 
export const AXIOS_IS_LOADING = 'AXIOS_IS_LOADING';
export const MODAL_IS_LOADING = 'MODAL_IS_LOADING';
export const FETCH_FEATURED_PLAYLIST = 'FETCH_FEATURED_PLAYLIST';
export const FETCH_MINI_SPORTS_MENU = 'FETCH_MINI_SPORTS_MENU';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const OPEN_SPLITS = 'OPEN_SPLITS';
export const CLOSE_SPLITS = 'CLOSE_SPLITS';
export const SET_CURRENT_SEARCH =  'SET_CURRENT_SEARCH';
export const SET_CURRENT_SEARCH_TERM =  'SET_CURRENT_SEARCH_TERM';
export const UPDATE_FORGOT_PASSWORD_EMAIL = 'UPDATE_FORGOT_PASSWORD_EMAIL';
export const UPDATE_USER_THEME =  'UPDATE_USER_THEME';
export const UPDATE_AUTOPLAY = 'UPDATE_AUTOPLAY';
export const UPDATE_FEATURED_AUTOPLAY = 'UPDATE_FEATURED_AUTOPLAY';
export const TOGGLE_AUTOPLAY = 'TOGGLE_AUTOPLAY';
export const TOGGLE_WAVEPLAYER = 'TOGGLE_WAVEPLAYER';
export const SET_VOCAL_FILTER = 'SET_VOCAL_FILTER';
export const UPDATE_AFFILIATE_ERROR =  'UPDATE_AFFILIATE_ERROR';

